import * as React from 'react';
import clsx from 'clsx';
import styles from './Input.module.scss';

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  className?: string;
  endIcon?: React.ReactNode;
};

const Input = React.forwardRef<HTMLInputElement, InputProps>(function Input(
  props,
  ref
) {
  const { className, type, endIcon, ...other } = props;

  return (
    <div className={clsx(styles.root, className)}>
      <input
        ref={ref}
        className={clsx(styles.input, { [styles.search]: type === 'search' })}
        type={type}
        {...other}
      />
      <div>{endIcon}</div>
    </div>
  );
});

export default Input;
