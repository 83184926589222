import * as React from 'react';
import sortBy from 'lodash/sortBy';
import debounce from 'lodash/debounce';
import { csvParse, DSVRowArray, DSVRowString } from 'd3-dsv';
import { client } from 'libs/sanity';
import DatasetToggleDesktop from 'blocks/DatasetToggleDesktop';
import DatasetToggleMobile from 'blocks/DatasetToggleMobile';
import Search from 'components/Search';
import SearchBar from 'components/SearchBar';
import { SearchData } from 'components/Search/SearchContext';
import SearchResults from 'components/SearchResults';
import BottomSheet from 'components/BottomSheet';
import DataLayerToggle from 'components/DataLayerToggle';
import AppBar from 'components/AppBar';
import TemporalToggleList from 'components/TemporalToggleList';
import ExpandMore from 'components/Icon/ExpandMore';
import Button from 'components/Button';
import DataList from 'blocks/DataList';
import DataVisualisationToggle from 'blocks/DataVisualisationToggle';
import Map, { MapProps } from 'components/Map';
import Graph from 'components/Graph';
import styles from './App.module.scss';
import getRegionTypeName from 'utils/getRegionTypeName';
import RegionButtonGroup from 'blocks/RegionButtonGroup';
import clsx from 'clsx';
import unionBy from 'lodash/unionBy';

enum RegionTypes {
  kommun = 'kommun',
  lan = 'lan',
  region = 'region',
  country = 'country',
}
export type RegionType = keyof typeof RegionTypes;

type GeoData = {
  shapeKey: string;
  region: RegionType;
  data: string;
};

type ParsedRowData = {
  id: string;
  name: string;
  lan_id?: string;
} & Record<string, number | null>;

type BaseDataset = {
  title: string;
  description: string;
  sortOrder: number;
  kommun?: string;
  lan?: string;
  region?: string;
  country?: string;
};

type RawDataset = BaseDataset & {
  _id: string;
};

export type ParsedDataset = {
  id: string;
  kommunData?: DSVRowArray<string>;
  lanData?: DSVRowArray<string>;
  regionData?: DSVRowArray<string>;
  countryData?: DSVRowArray<string>;
} & BaseDataset;

export type Dataset = Record<string, ParsedDataset>;

const geoDataQuery = `*[_type == "geodata"]{
  _id,
  region,
  shapeKey,
  "data": geojson.asset->url
}`;

const datasetQuery = `*[_type == "dataset"] | order(sortOrder){
  _id,
  title,
  description,
  sortOrder,
  "kommun": csvKommun.asset->url,
  "lan": csvLan.asset->url,
  "region": csvRegioner.asset->url,
  "country": csvCountry.asset->url
}`;

export enum VisualisationModes {
  map = 'map',
  graph = 'graph',
  list = 'list',
}
export type VisualisationMode = keyof typeof VisualisationModes;

const defaultVisualisationData = [
  {
    id: VisualisationModes.map,
    title: 'Karta',
    icon: 'map' as const,
    disabled: false,
  },
  {
    id: VisualisationModes.graph,
    title: 'Graf',
    icon: 'bars' as const,
    disabled: false,
  },
  {
    id: VisualisationModes.list,
    title: 'Lista',
    icon: 'list' as const,
    disabled: false,
  },
];
const mediaQuery = '(min-width:768px)';

// NOTE: function takes optional geoType to be prefixed to the id to ensure that
// each geo type doesn't conflict with other ones
function parseRows(d: DSVRowString<string>, geoType?: string) {
  const { name, id, lan_id, type, unit, ...rest } = d;

  const data = Object.entries(rest).reduce(
    (map: Record<string, number>, [key, value]) => {
      map[key] = value === 'null' ? JSON.parse(value) : +(value as string);
      return map;
    },
    {}
  );

  return {
    name,
    id: geoType ? `${geoType}_${id}` : id,
    type,
    unit,
    ...(lan_id && { lan_id: `lan_${lan_id}` }),
    ...data,
  };
}

function getYearsFromData(
  activeDataset: ParsedDataset,
  activeRegionType: 'kommunData' | 'lanData' | 'regionData'
): string[] {
  let years = [];
  if (activeDataset.countryData) {
    years = sortBy(
      activeDataset.countryData?.columns.filter((col) => /^[0-9]*$/gm.test(col))
    ).reverse();
  } else {
    years = sortBy(
      activeDataset[activeRegionType]?.columns.filter((col) =>
        /^[0-9]*$/gm.test(col)
      )
    ).reverse();
  }

  return years;
}

function setVH() {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  const vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

const App: React.FC = () => {
  const [matches, setMatches] = React.useState(
    window?.matchMedia(mediaQuery).matches
  );
  const [activeFeatureId, setActiveFeatureId] = React.useState<string>();
  const [activeCountryRegion, setActiveCountryRegion] = React.useState<
    Omit<RegionType, 'country'>
  >('lan');
  const [activeRegionType, setActiveRegionType] = React.useState<RegionType>(
    'country'
  );
  const [activeDataset, setActiveDataset] = React.useState<string>();
  const [
    activeVisualisation,
    setActiveVisualisation,
  ] = React.useState<VisualisationMode>('map');
  const [searchValue, setSearchValue] = React.useState<string>();
  const [visualisationData, setVisualisationData] = React.useState(
    defaultVisualisationData
  );
  const [searchData, setSearchData] = React.useState<SearchData[]>([]);
  const [datasets, setDatasets] = React.useState<Dataset>();
  const [years, setYears] = React.useState<string[]>();
  const [activeYear, setActiveYear] = React.useState<string>();
  const [openDatasetMenu, setOpenDatasetMenu] = React.useState(false);
  const [disableGeoData, setDisableGeoData] = React.useState(false);
  const [
    openDataVisualisationMenu,
    setOpenDataVisualisationMenu,
  ] = React.useState(false);
  const [geoData, setGeoData] = React.useState<Pick<MapProps, 'geoData'>>();

  const handleCloseDatasetMenu = React.useCallback(
    () => setOpenDatasetMenu(false),
    []
  );
  const handleOpenDatasetMenu = React.useCallback(
    () => setOpenDatasetMenu(true),
    []
  );

  const handleOpenDataVisualisationMenu = React.useCallback(
    () => setOpenDataVisualisationMenu(true),
    []
  );
  const handleCloseDataVisualisationMenu = React.useCallback(
    () => setOpenDataVisualisationMenu(false),
    []
  );

  const handleDatasetChange = React.useCallback(
    (id: string) => {
      if (datasets) {
        const dataset = datasets[id];
        const years = getYearsFromData(
          dataset,
          `${activeCountryRegion}Data` as
            | 'kommunData'
            | 'lanData'
            | 'regionData'
        );

        const disableGeoData =
          !dataset.kommunData && !dataset.lanData && !dataset.regionData;
        const disableListData =
          // @ts-ignore
          disableGeoData && dataset?.countryData?.length < 2;

        const supportedVisualisations = [
          ...(!disableGeoData ? ['map'] : []),
          ...(!disableListData ? ['list'] : []),
          ...(years.length > 1 ? ['graph'] : []),
        ].filter(Boolean);

        setDisableGeoData(disableGeoData);

        setVisualisationData(
          visualisationData.map((d) => {
            return {
              ...d,
              disabled: supportedVisualisations.indexOf(d.id) === -1,
            };
          })
        );

        if (dataset.kommunData && (!dataset.lanData || !dataset.regionData)) {
          setActiveCountryRegion('kommun');
          setActiveRegionType('kommun');
        } else {
          setActiveRegionType('country');
        }
        setActiveFeatureId('');
        setActiveDataset(id);
        setYears(years);
        setActiveYear((current) =>
          years.includes(current ?? '') ? current : years[0]
        );

        setActiveVisualisation((current) =>
          supportedVisualisations.indexOf(current) >= 0
            ? current
            : (supportedVisualisations[0] as VisualisationMode)
        );

        setSearchValue(undefined);
      }
    },
    [activeCountryRegion, datasets, visualisationData]
  );

  const handleGeoViewSelect = React.useCallback(
    (type: RegionType) => {
      setActiveCountryRegion(type);
      if (datasets) {
        const years = getYearsFromData(
          datasets[activeDataset as any],
          `${type}Data` as 'kommunData' | 'lanData' | 'regionData'
        );
        setYears(years);
      }
    },
    [activeDataset, datasets]
  );

  const handleVisualisationChange = React.useCallback(
    (key: VisualisationMode) => {
      setActiveVisualisation(key);
      if (key !== 'list') {
        // clear the stored list filter
        sessionStorage.removeItem('ekokartan_list_filter');
      }
      if (openDataVisualisationMenu) {
        setOpenDataVisualisationMenu(false);
      }
    },
    [openDataVisualisationMenu]
  );

  const handleTemporalChange = React.useCallback(
    (year: string) => setActiveYear(year),
    []
  );

  const handleFeatureChange = React.useCallback(
    (id: string, regionType: RegionType) => {
      setActiveFeatureId(id);
      setActiveRegionType(regionType);
      const searchValue = searchData.find((d) => d.id === id);

      if (searchValue) {
        setSearchValue(
          `${searchValue.name} ${getRegionTypeName(searchValue.type)}`
        );
      } else {
        setSearchValue(undefined);
      }
    },
    [searchData]
  );

  const handleSearchSelect = React.useCallback(
    (id: string, type: 'lan' | 'kommun', value: string) => {
      setActiveFeatureId(id);
      setActiveRegionType(type);
      setActiveCountryRegion(type);
      setSearchValue(value);
    },
    []
  );

  const handleReset = React.useCallback(() => {
    setActiveFeatureId(undefined);
    setActiveRegionType('country');
    setSearchValue(undefined);
  }, []);

  React.useEffect(() => {
    (async function fetchData() {
      try {
        const geoRes = await client.fetch<GeoData[]>(geoDataQuery);
        const geoData = {};

        // TODO: update how we want to parse and store this data
        for (const item of geoRes) {
          const res = await fetch(item.data);
          const json = await res.json();
          // @ts-ignore
          geoData[item.shapeKey] = {
            ...json,
            features: json.features.map((feature: any) => ({
              ...feature,
              properties: {
                ...feature.properties,
                id: `${item.shapeKey}_${feature.properties.id}`,
              },
            })),
          };
        }

        const datasetRes = (await client.fetch(datasetQuery)) as RawDataset[];
        const parsedData = datasetRes.reduce((map: Dataset, d) => {
          map[d._id] = { ...d, id: d._id };
          return map;
        }, {});

        for (const id of Object.keys(parsedData)) {
          if (
            parsedData[id].kommun ||
            parsedData[id].lan ||
            parsedData[id].region
          ) {
            const [kommunRes, lanRes, regionRes] = await Promise.all([
              ...(parsedData[id].kommun
                ? [fetch(parsedData[id].kommun as string)]
                : []),
              ...(parsedData[id].lan
                ? [fetch(parsedData[id].lan as string)]
                : []),
              ...(parsedData[id].region
                ? [fetch(parsedData[id].region as string)]
                : []),
            ]);
            const [kommunText, lanText, regionText] = await Promise.all([
              ...(kommunRes ? [kommunRes.text()] : []),
              ...(lanRes ? [lanRes.text()] : []),
              ...(regionRes ? [regionRes.text()] : []),
            ]);

            let searchData: any[] = [];

            if (kommunText) {
              const kommun = csvParse(kommunText, (d) =>
                parseRows(d, 'kommun')
              );
              parsedData[id].kommunData = kommun;
              searchData = [
                ...searchData,
                ...(kommun as any).map((d: ParsedRowData) => ({
                  name: d.name,
                  type: 'kommun' as const,
                  id: d.id,
                })),
              ];
            }

            if (lanText) {
              const lan = csvParse(lanText, (d) => parseRows(d, 'lan'));
              parsedData[id].lanData = lan;
              searchData = [
                ...searchData,
                ...(lan as any).map((d: ParsedRowData) => ({
                  name: d.name,
                  type: 'lan' as const,
                  id: d.id,
                })),
              ];
            }

            if (regionText) {
              const region = csvParse(regionText, (d) =>
                parseRows(d, 'region')
              );
              parsedData[id].regionData = region;
              searchData = [
                ...searchData,
                ...(region as any).map((d: ParsedRowData) => ({
                  name: d.name,
                  type: 'region' as const,
                  id: d.id,
                })),
              ];
            }

            setSearchData((existing) =>
              unionBy(
                searchData.filter((d) => d.name),
                existing,
                'name'
              )
            );
          }

          if (parsedData[id].country) {
            const res = await fetch(parsedData[id].country as string);
            const text = await res.text();
            const data = csvParse(text, (d) => parseRows(d));
            parsedData[id].countryData = data;
          }
        }

        const activeDataset = parsedData[Object.keys(parsedData)[0]];
        const years = getYearsFromData(
          activeDataset,
          `${activeCountryRegion}Data` as
            | 'kommunData'
            | 'lanData'
            | 'regionData'
        );

        setGeoData(geoData as Pick<MapProps, 'geoData'>);
        setYears(years);
        setActiveYear(years[0]);
        setDatasets(parsedData);
        setActiveDataset(activeDataset.id);
      } catch (e) {
        console.error(e);
      }
    })();

    const handleChange = (e: MediaQueryListEvent) => {
      setMatches(e.matches);
    };

    setVH();
    const handleResize = debounce(() => {
      setVH();
    }, 150);

    const matchMedia = window.matchMedia(mediaQuery);
    window.addEventListener('resize', handleResize);

    try {
      matchMedia.addEventListener('change', handleChange);
      return () => {
        window.removeEventListener('resize', handleResize);
        matchMedia.removeEventListener('change', handleChange);
      };
    } catch (e) {
      // NOTE: this catch is to support older versions of Safari
      matchMedia.addListener(handleChange);
      return () => {
        window.removeEventListener('resize', handleResize);
        matchMedia.removeListener(handleChange);
      };
    }
  }, []);

  if (!datasets || !activeDataset || !years || !activeYear) {
    return null;
  }

  return (
    <main className={styles.root}>
      {matches ? (
        <>
          <div className={styles.mainView}>
            <div className={styles.header}>
              <Search
                value={searchValue ?? ''}
                data={(searchData as SearchData[]).filter(
                  ({ type }: { type: string }) => {
                    const d = datasets[activeDataset];
                    if (!d.kommunData && type === 'kommun') {
                      return false;
                    }
                    if (!d.lanData && type === 'lan') {
                      return false;
                    }
                    if (!d.regionData && type === 'region') {
                      return false;
                    }

                    return true;
                  }
                )}
                onSelect={handleSearchSelect}
              >
                <SearchBar
                  className={styles.searchBar}
                  onClear={handleReset}
                  placeholder="Sök län, regioner eller kommun"
                  disabled={disableGeoData}
                />
                <SearchResults />
              </Search>

              {activeRegionType === 'country' &&
                activeVisualisation === 'map' && (
                  <RegionButtonGroup
                    dataset={datasets[activeDataset]}
                    activeCountryRegion={activeCountryRegion}
                    onSelect={handleGeoViewSelect}
                    className={clsx(styles.buttonGroup)}
                  />
                )}
            </div>

            <div className={styles.dataView}>
              <div className={styles.temporalMenu}>
                <TemporalToggleList
                  key={activeDataset}
                  onChange={handleTemporalChange}
                  activeItem={activeYear}
                  listItems={years}
                  disabled={activeVisualisation === 'graph'}
                  direction="vertical"
                />
              </div>
              <section className={styles.innerRoot}>
                {activeVisualisation === 'map' && geoData && (
                  <Map
                    geoData={geoData as Pick<MapProps, 'geoData'>}
                    activeCountryRegion={activeCountryRegion}
                    activeFeatureId={activeFeatureId}
                    activeRegionType={activeRegionType}
                    data={datasets[activeDataset]}
                    activeYear={activeYear}
                    onFeatureChange={handleFeatureChange}
                  />
                )}
                {activeVisualisation === 'list' && (
                  <DataList
                    data={datasets[activeDataset]}
                    activeRegionType={activeRegionType}
                    activeYear={activeYear}
                    activeFeatureId={activeFeatureId}
                    onRegionTypeChange={handleGeoViewSelect}
                  />
                )}
                {activeVisualisation === 'graph' && (
                  <Graph
                    data={datasets[activeDataset]}
                    years={years}
                    activeRegionType={activeRegionType}
                    activeFeatureId={activeFeatureId}
                    activeDataSet={activeDataset}
                  />
                )}
              </section>
            </div>
          </div>
          <div className={styles.sidepanel}>
            {datasets && activeDataset && (
              <DatasetToggleDesktop
                datasets={datasets}
                activeDatasetId={activeDataset}
                onChange={handleDatasetChange}
                activeVisualisation={activeVisualisation}
                onVisualisationChange={handleVisualisationChange}
                data={visualisationData}
              />
            )}
          </div>
        </>
      ) : (
        <>
          <div className={styles.headerRoot}>
            <Search
              value={searchValue}
              data={(searchData as SearchData[]).filter(
                ({ type }: { type: string }) => {
                  const d = datasets[activeDataset];
                  if (!d.kommunData && type === 'kommun') {
                    return false;
                  }
                  if (!d.lanData && type === 'lan') {
                    return false;
                  }
                  if (!d.regionData && type === 'region') {
                    return false;
                  }

                  return true;
                }
              )}
              onSelect={handleSearchSelect}
            >
              <AppBar className={styles.appbar}>
                <SearchBar
                  className={styles.searchBar}
                  onClear={handleReset}
                  placeholder="Sök län, regioner eller kommun"
                  disabled={disableGeoData}
                />

                <DataLayerToggle onClick={handleOpenDataVisualisationMenu} />
              </AppBar>
              <SearchResults />
            </Search>

            <TemporalToggleList
              key={activeDataset}
              onChange={handleTemporalChange}
              activeItem={activeYear}
              listItems={years}
              direction="horizontal"
              disabled={activeVisualisation === 'graph'}
            />
          </div>

          <section className={styles.innerRoot}>
            {activeVisualisation === 'map' && geoData && (
              <Map
                geoData={geoData as Pick<MapProps, 'geoData'>}
                data={datasets[activeDataset]}
                activeCountryRegion={activeCountryRegion}
                activeFeatureId={activeFeatureId}
                activeRegionType={activeRegionType}
                activeYear={activeYear}
                onFeatureChange={handleFeatureChange}
              />
            )}
            {activeVisualisation === 'list' && (
              <DataList
                data={datasets[activeDataset]}
                activeRegionType={activeRegionType}
                activeYear={activeYear}
                activeFeatureId={activeFeatureId}
                onRegionTypeChange={handleGeoViewSelect}
              />
            )}
            {activeVisualisation === 'graph' && (
              <Graph
                data={datasets[activeDataset]}
                years={years}
                activeRegionType={activeRegionType}
                activeFeatureId={activeFeatureId}
                activeDataSet={activeDataset}
              />
            )}
          </section>

          <div className={styles.actionButton}>
            {datasets && activeDataset && (
              <Button
                onClick={handleOpenDatasetMenu}
                endAdornment={<ExpandMore fill="#0c687a" />}
              >
                <span>{datasets[activeDataset].title}</span>
              </Button>
            )}
          </div>
          <BottomSheet
            open={openDatasetMenu}
            onClose={handleCloseDatasetMenu}
            header={datasets[activeDataset].title}
          >
            {datasets && activeDataset && (
              <DatasetToggleMobile
                datasets={datasets}
                activeDataset={activeDataset}
                onChange={handleDatasetChange}
              />
            )}
          </BottomSheet>

          <BottomSheet
            open={openDataVisualisationMenu}
            onClose={handleCloseDataVisualisationMenu}
            header="data visualisering"
          >
            <DataVisualisationToggle
              hideHeader
              activeVisualisation={activeVisualisation}
              onChange={handleVisualisationChange}
              data={visualisationData}
            />
          </BottomSheet>
        </>
      )}
    </main>
  );
};

export default App;
