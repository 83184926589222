import * as React from 'react';
import { animated, useTransition } from 'react-spring';
import Modal, { ModalProps } from 'components/Modal';
import styles from './SearchResultsFullPage.module.scss';
import { SearchContextProps } from 'components/Search/SearchContext';

type Props = Pick<ModalProps, 'open'> & {
  handleClick: SearchContextProps['onSelect'];
};

const SearchResultsFullPage: React.FC<Props> = (props) => {
  const { open, children, handleClick } = props;

  const transitions = useTransition(open, null, {
    from: {
      opacity: 0,
    },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { tension: 120, friction: 16, clamp: true },
  });

  return (
    <Modal hideBackdrop open={open}>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <animated.div
              key={key}
              className={styles.modalRoot}
              style={props}
              onClick={handleClick}
            >
              {children}
            </animated.div>
          )
      )}
    </Modal>
  );
};

export default SearchResultsFullPage;
