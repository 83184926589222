import clsx from 'clsx';
import Button from 'components/Button';
import ButtonGroup, { ButtonGroupProps } from 'components/ButtonGroup';
import { ParsedDataset, RegionType } from 'containers/App';
import * as React from 'react';
import styles from './RegionButtonGroup.module.scss';

export type RegionButtonGroupProps = ButtonGroupProps & {
  activeCountryRegion: Omit<RegionType, 'country'>;
  onSelect?(d: RegionType): void;
  className?: string;
  dataset: ParsedDataset;
};

const RegionButtonGroup: React.FC<RegionButtonGroupProps> = (props) => {
  const { activeCountryRegion, onSelect, className, dataset, ...rest } = props;

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    sessionStorage.setItem(
      'ekokartan_list_filter',
      e.currentTarget.dataset.value ?? 'lan'
    );
    onSelect?.(e.currentTarget.dataset.value as RegionType);
  };

  if (
    [dataset.kommunData, dataset.regionData, dataset.lanData].filter(
      (dataset) => dataset
    ).length <= 1
  ) {
    return null;
  }

  return (
    <ButtonGroup className={clsx(styles.root, className)} {...rest}>
      <Button
        data-value="lan"
        onClick={handleClick}
        className={clsx(
          styles.button,
          activeCountryRegion === 'lan' && styles.active
        )}
      >
        Län
      </Button>
      <Button
        data-value="region"
        onClick={handleClick}
        className={clsx(
          styles.button,
          activeCountryRegion === 'region' && styles.active
        )}
      >
        Regioner
      </Button>
      <Button
        data-value="kommun"
        onClick={handleClick}
        className={clsx(
          styles.button,
          activeCountryRegion === 'kommun' && styles.active
        )}
      >
        Kommun
      </Button>
    </ButtonGroup>
  );
};

export default RegionButtonGroup;
