import * as React from 'react';
import clsx from 'clsx';
import styles from './TemporalFilter.module.scss';

export type TemporalFilterProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  active?: boolean;
};

const TemporalFilter: React.FC<TemporalFilterProps> = (props) => {
  const { active, ...rest } = props;
  return (
    <button className={clsx(styles.filter, active && styles.active)} {...rest}>
      {props.children}
    </button>
  );
};

export default TemporalFilter;
