import sortBy from 'lodash/sortBy';
import * as React from 'react';
import getRegionTypeName from 'utils/getRegionTypeName';

export type SearchData = {
  name: string;
  id: string;
  type: 'kommun' | 'lan' | 'region';
};

export type SearchContextProps = {
  focused: boolean;
  setFocused: React.Dispatch<React.SetStateAction<boolean>>;
  searchHistory: SearchData[];
  setSearchHistory: React.Dispatch<React.SetStateAction<SearchData[]>>;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  results: SearchData[];
  data: SearchData[];
  searchBarRef: React.MutableRefObject<HTMLDivElement | undefined>;
  onSelect(e: React.MouseEvent<HTMLButtonElement | HTMLDivElement>): void;
  onRemove(e: React.MouseEvent<HTMLButtonElement>): void;
};

const storageKey = 'ekokartan-search-history';
const SearchContext = React.createContext<SearchContextProps>({
  focused: false,
  setFocused: () => {},
  searchHistory: [],
  setSearchHistory: () => {},
  value: '',
  setValue: () => {},
  results: [],
  data: [],
  searchBarRef: { current: undefined },
  onSelect: () => {},
  onRemove: () => {},
});

export type SearchContextProviderProps = {
  focused?: boolean;
  value?: string;
  data: SearchData[];
  onSelect(id: string, type: 'kommun' | 'lan' | 'region', value: string): void;
};

const SearchContextProvider: React.FC<SearchContextProviderProps> = (props) => {
  const {
    value: valueProp = '',
    focused: focusedProp,
    data,
    children,
    onSelect,
  } = props;
  const [focused, setFocused] = React.useState(focusedProp ?? false);
  const [value, setValue] = React.useState(valueProp);
  const [searchHistory, setSearchHistory] = React.useState<SearchData[]>(
    JSON.parse(localStorage.getItem(storageKey) as string) ?? []
  );
  const searchBarRef = React.useRef<HTMLDivElement>();

  const handleSelect = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      const dataset = (e.currentTarget as HTMLElement).dataset;
      const item = data.find((d) => d.id === dataset.id);

      if (item) {
        setValue(`${item.name} ${getRegionTypeName(item.type)}`);

        const history = JSON.parse(localStorage.getItem(storageKey) as string);
        if (history) {
          const index = history.findIndex(
            (history: SearchData) => history.id === item.id
          );

          let newHistory = history;
          if (index !== -1) {
            newHistory = [
              item,
              ...history.slice(0, index),
              ...history.slice(index + 1, history.length),
            ];
          } else {
            newHistory = [item, ...history].slice(0, 5);
          }
          localStorage.setItem(storageKey, JSON.stringify(newHistory));
          setSearchHistory(newHistory);
        } else {
          localStorage.setItem(storageKey, JSON.stringify([item]));
          setSearchHistory([item]);
        }
        setFocused(false);
        onSelect(
          item.id,
          item.type,
          `${item.name} ${getRegionTypeName(item.type)}`
        );
      } else {
        setFocused(false);
      }
    },
    [data, onSelect]
  );

  const handleRemove = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      const index = +(e.currentTarget.dataset.index as string);
      if (searchHistory[index]) {
        const history = [
          ...searchHistory.slice(0, index),
          ...searchHistory.slice(index + 1),
        ];
        localStorage.setItem(storageKey, JSON.stringify(history));
        setSearchHistory(history);
      }
    },
    [searchHistory]
  );

  const results = React.useMemo(() => {
    if (value.length > 1) {
      return sortBy(
        data.filter((d) => d.name.toLowerCase().includes(value.toLowerCase())),
        'name'
      );
    }

    return [];
  }, [data, value]);

  React.useEffect(() => {
    setValue(valueProp);
  }, [valueProp]);

  return (
    <SearchContext.Provider
      value={{
        focused,
        setFocused,
        searchHistory,
        setSearchHistory,
        value,
        setValue,
        results,
        data: sortBy(data, 'name'),
        searchBarRef,
        onSelect: handleSelect,
        onRemove: handleRemove,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export const useSearchContext = (): SearchContextProps => {
  return React.useContext(SearchContext);
};

export default SearchContextProvider;
