import clsx from 'clsx';
import * as React from 'react';
import styles from './List.module.scss';

export type ListProps = React.PropsWithChildren<{
  className?: string;
  component?: 'ol' | 'ul';
  disablePadding?: boolean;
  subheader?: React.ReactNode;
}>;

const List = React.forwardRef<HTMLUListElement | HTMLOListElement, ListProps>(
  function List(props, ref) {
    const {
      children,
      component: Component = 'ul',
      className,
      disablePadding = false,
      subheader,
      ...other
    } = props;

    return (
      <>
        {subheader}

        <Component
          // @ts-ignore
          ref={ref}
          className={clsx(
            styles.root,
            {
              [styles.padding]: !disablePadding,
              [styles.subheader]: subheader,
            },
            className
          )}
          {...other}
        >
          {children}
        </Component>
      </>
    );
  }
);

export default List;
