import * as React from 'react';
import ScrollableList from 'components/ScrollableList';
import BlockContent from '@sanity/block-content-to-react';
import Card from 'components/Card';
import styles from './DatasetToggleMobile.module.scss';
import { ReactComponent as EkomatCentrum } from 'assets/ekomatcentrum.svg';
import { ReactComponent as Jordbruksverket } from 'assets/jordbruksverket.svg';

type Dataset = Record<
  string,
  {
    id: string;
    title: string;
    description: string;
  }
>;

export type DatasetToggleMobileProps = {
  activeDataset: string;
  datasets: Dataset;
  onChange?(id: string): void;
};

const DatasetToggleMobile: React.FC<DatasetToggleMobileProps> = (props) => {
  const { activeDataset, datasets, onChange } = props;
  const descriptionRef = React.useRef(null);
  const activeDatasetIndex = React.useMemo(
    () => Object.keys(datasets).indexOf(activeDataset),
    [activeDataset, datasets]
  );

  const handleDatasetSelect = (e: React.MouseEvent) => {
    const dataKey =
      (e.currentTarget as HTMLDivElement).dataset.value || activeDataset;

    onChange?.(dataKey);
    // @ts-ignore
    descriptionRef.current?.scrollTo(0, 0);
  };

  return (
    <div className={styles.root}>
      <div className={styles.description} ref={descriptionRef}>
        <BlockContent blocks={datasets[activeDataset].description} />
      </div>
      <ScrollableList activeChildIndex={activeDatasetIndex}>
        <div className={styles.datasetList}>
          {Object.values(datasets).map((data) => (
            <Card
              key={data.id}
              data-value={data.id}
              onClick={handleDatasetSelect}
              text={data.title}
              variant={data.id === activeDataset ? 'active' : 'default'}
            />
          ))}
        </div>
      </ScrollableList>
      <div className={styles.partnershipRoot}>
        <EkomatCentrum />
        <Jordbruksverket />
      </div>
    </div>
  );
};

export default DatasetToggleMobile;
