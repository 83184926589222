export default function getAccumulatedData(data: any, years: number[]) {
  return data.reduce((obj: any, d: any) => {
    years.forEach((year) => {
      // @ts-ignore
      const v = d[year];
      if (v) {
        if (obj[year]) {
          obj[year].push(v);
        } else {
          obj[year] = [v];
        }
      }
    });
    return obj;
  }, {});
}
