import * as React from 'react';
import { DSVRowArray } from 'd3-dsv';
import clsx from 'clsx';
import maxBy from 'lodash/maxBy';
import orderBy from 'lodash/orderBy';
import { ParsedDataset, RegionType } from 'containers/App';
import List from 'components/List';
import ListItem from 'components/ListItem';
import Bar from 'components/Bar';
import Button from 'components/Button';
import MenuDialog from 'components/MenuDialog';
import ExpandMore from 'components/Icon/ExpandMore';
import styles from './DataList.module.scss';
import getUnitName from 'utils/getUnitName';

export type DataListProps = {
  data: ParsedDataset;
  activeRegionType: RegionType;
  activeFeatureId?: string;
  activeYear: string;
  onRegionTypeChange(key: RegionType): void;
};

type ListData = {
  name: string;
  value: string;
  pos: number;
  id: string;
  type?: string;
  unit?: string;
};

function getListData(
  data: ParsedDataset,
  key: 'countryData' | 'lanData' | 'kommunData' | 'regionData',
  year: string
) {
  return orderBy(
    data[key]?.map((d) => {
      return {
        name: d.name,
        value: d[year],
        id: d.id,
        ...(d.type && { type: d.type }),
        ...(d.unit && { unit: d.unit }),
      };
    }),
    ({ value }) => (value === null ? -1 : value),
    ['desc']
  ).reduce((map: ListData[], d, i) => {
    let pos = 1;

    if (i > 0) {
      const prev = map[i - 1];
      pos = +(prev.value === d.value ? prev.pos : i + 1);
    }

    map.push({
      ...d,
      pos,
    } as ListData);
    return map;
  }, []);
}
const filters: Record<string, string> = {
  lan: 'Län',
  kommun: 'Kommun',
  region: 'Region',
};

const DataList: React.FC<DataListProps> = (props) => {
  const {
    data,
    activeYear,
    activeRegionType,
    activeFeatureId,
    onRegionTypeChange,
  } = props;
  const buttonRef = React.useRef<HTMLButtonElement>();
  const [open, setOpen] = React.useState(false);
  const [activeFilter, setActiveFilter] = React.useState(
    sessionStorage.getItem('ekokartan_list_filter') || 'lan'
  );

  const handleClick = React.useCallback((e) => {
    e.stopPropagation();
    setOpen((prev) => !prev);
  }, []);

  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  const handleFilterClick = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      const key = (e.currentTarget as HTMLDivElement).dataset.key as RegionType;

      setActiveFilter(key);
      onRegionTypeChange(key);
      sessionStorage.setItem('ekokartan_list_filter', key);
      setOpen(false);
    },
    [onRegionTypeChange]
  );

  const listData = React.useMemo(() => {
    if (activeRegionType === 'country') {
      const key =
        data.countryData && data.countryData?.length > 1
          ? 'countryData'
          : (`${activeFilter}Data` as 'lanData' | 'kommunData');

      return getListData(data, key, activeYear);
    }

    if (activeRegionType === 'region') {
      const key =
        data.regionData && data.regionData?.length > 1
          ? 'regionData'
          : (`${activeFilter}Data` as 'lanData' | 'kommunData' | 'regionData');

      return getListData(data, key, activeYear);
    }

    if (activeRegionType === 'lan') {
      return getListData(
        {
          ...data,
          kommunData: data.kommunData?.filter(
            (d) => d.lan_id === activeFeatureId
          ) as DSVRowArray<string>,
        },
        'kommunData',
        activeYear
      );
    }

    if (activeRegionType === 'kommun') {
      return getListData(data, 'kommunData', activeYear);
    }

    return [];
  }, [activeFeatureId, activeFilter, activeRegionType, activeYear, data]);

  const refs = listData.reduce<Record<string, React.RefObject<HTMLLIElement>>>(
    (acc, value) => {
      acc[value.id as string] = React.createRef<HTMLLIElement>();
      return acc;
    },
    {}
  );

  React.useEffect(() => {
    if (activeRegionType === 'kommun' && activeFeatureId) {
      setActiveFilter('kommun');
      refs[activeFeatureId]?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    } else if (activeRegionType === 'region' && activeFeatureId) {
      setActiveFilter('region');
      refs[activeFeatureId]?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    } else {
      if (listData && listData.length > 0) {
        refs[listData[0].id]?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }
  }, [activeFeatureId, activeRegionType, listData, refs, activeYear]);

  const maxValue = React.useMemo(() => {
    return +(maxBy(listData, 'value')?.value as string);
  }, [listData]);

  return (
    <div className={styles.root}>
      <div className={styles.listHeader}>
        {(!data.kommunData && !data.regionData && !data.lanData) ||
        (data.kommunData && !data.regionData && !data.lanData) ||
        (!data.kommunData && data.regionData && !data.lanData) ||
        (!data.kommunData && !data.regionData && data.lanData) ||
        (data.countryData && data.countryData?.length > 1) ||
        activeFeatureId ? (
          <h6>
            {activeRegionType === 'lan' || activeRegionType === 'kommun'
              ? 'kommuner'
              : listData[0]?.type ?? data.title}
          </h6>
        ) : (
          <Button
            ref={buttonRef as any}
            endAdornment={<ExpandMore />}
            onClick={handleClick}
            className={styles.filterButton}
          >
            {filters[activeFilter]}
          </Button>
        )}

        <div>{getUnitName(listData[0]?.unit || '')}</div>
      </div>

      <List className={styles.list}>
        {listData?.map((d) => (
          <ListItem
            key={d.id}
            ref={refs[d.id]}
            showListStyles
            className={clsx(styles.listItem, {
              [styles.highlighted]: d.id === activeFeatureId,
            })}
          >
            <div className={styles.listItemInner}>
              <span>{d.pos}.</span>
              <span>{d.name}</span>
            </div>
            <div className={styles.barRoot}>
              <Bar
                value={d.value !== null ? +d.value : d.value}
                width={!d.value ? 0 : (+d.value / maxValue) * 100}
              />
            </div>
          </ListItem>
        ))}
      </List>

      <MenuDialog
        open={open}
        anchorEl={buttonRef}
        onClose={handleClose}
        hideBackdrop
      >
        <List className={styles.menuDialog} disablePadding>
          {Object.entries(filters).map(([key, value]) => (
            <ListItem
              key={key}
              component="button"
              onClick={handleFilterClick}
              data-key={key}
              className={clsx({
                [styles.active]: key === activeFilter,
              })}
            >
              {value}
            </ListItem>
          ))}
        </List>
      </MenuDialog>
    </div>
  );
};

export default DataList;
