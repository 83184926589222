import * as React from 'react';
import clsx from 'clsx';
import styles from './Bar.module.scss';
import getValueRating from 'utils/getValueRating';

export type BarProps = {
  value: number | null;
  width: number;
  condensed?: boolean;
};

const Bar: React.FC<BarProps> = (props) => {
  const { value, width, condensed } = props;
  const rating = React.useMemo(() => getValueRating(value), [value]);
  return (
    <div
      className={clsx(
        styles.bar,
        styles[rating],
        condensed && styles.condensed
      )}
      style={{ width: `${width}%` }}
    >
      {value === null ? 'n/a' : value}
    </div>
  );
};

export default Bar;
