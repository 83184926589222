import * as React from 'react';
import clsx from 'clsx';
import styles from './Card.module.scss';
import { ReactComponent as Leaf } from '../../assets/Leaf.svg';

enum CardVariants {
  active = 'active',
  default = 'default',
}

export type CardProps = {
  onClick?(e: React.MouseEvent<HTMLDivElement>): void;
  text: string;
  variant: keyof typeof CardVariants;
};

const Card: React.FC<CardProps> = (props) => {
  const { variant = 'default', text, onClick, children, ...rest } = props;

  const transform = React.useMemo(
    () =>
      `translate(${Math.floor(
        Math.random() * Math.floor(-150)
      )}px, ${Math.floor(Math.random() * Math.floor(-100))}px)`,
    []
  );

  return (
    <div
      className={clsx(styles.root, styles[variant])}
      onClick={onClick}
      {...rest}
    >
      {variant !== 'active' && <div className={styles.overlay} />}
      <div className={styles.svgContainer} style={{ transform }}>
        <Leaf />
      </div>

      <span className={clsx(styles.cardHeader, styles[variant])}>{text}</span>
      {children}
    </div>
  );
};

export default Card;
