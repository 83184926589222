import * as React from 'react';
import debounce from 'lodash/debounce';
import { useSpring } from 'react-spring';
import clsx from 'clsx';
import { geoMercator, geoPath } from 'd3-geo';
import { RegionType, ParsedDataset } from 'containers/App';
import Tooltip from 'blocks/Tooltip';
import styles from './Map.module.scss';
import GeoShape, { GeoShapeProps } from './partials/GeoShape';

const projection = geoMercator()
  .center([16, 62.5])
  .scale(1100)
  .translate([window.innerWidth / 2, window.innerHeight / 2]);

const path = geoPath(projection);

export type MapProps = {
  geoData: any;
  activeFeatureId?: string;
  activeRegionType: RegionType;
  activeCountryRegion?: Omit<RegionType, 'country'>;
  activeYear: string;
  data: ParsedDataset;
  onFeatureChange(id: string, regionType: RegionType): void;
};

function getGeoFeatureData(dataset: any, activeYear = 'string') {
  return dataset.reduce((obj: GeoShapeProps['data'], item: any) => {
    obj[item.id as string] = {
      // @ts-ignore
      value: item[activeYear],
      parentId: item['lan_id'],
      name: item.name,
    };
    return obj;
  }, {});
}

const Map: React.FC<MapProps> = (props) => {
  const {
    geoData,
    data,
    activeFeatureId,
    activeRegionType,
    activeCountryRegion,
    activeYear,
    onFeatureChange,
  } = props;
  const [w, setWidth] = React.useState(window.innerWidth);
  const [h, setHeight] = React.useState(window.innerHeight);
  const [featureParentId, setFeatureParentId] = React.useState<
    string | undefined
  >(undefined);
  const [tooltip, setTooltip] = React.useState<
    { name: string; value: number | null } | undefined
  >();
  const hideTooltipTimeout = React.useRef(0);

  const [{ tooltipX, tooltipY }, set] = useSpring(() => ({
    tooltipX: 0,
    tooltipY: 0,
    config: { precision: 1 },
  }));

  const kommunData = React.useMemo(
    () =>
      data.kommunData ? getGeoFeatureData(data.kommunData, activeYear) : {},
    [activeYear, data.kommunData]
  );

  const lanData = React.useMemo(
    () => (data.lanData ? getGeoFeatureData(data.lanData, activeYear) : {}),
    [activeYear, data.lanData]
  );

  const regionData = React.useMemo(
    () =>
      data.regionData ? getGeoFeatureData(data.regionData, activeYear) : {},
    [activeYear, data.regionData]
  );

  const handleFeatureClick = React.useCallback(
    (e: React.MouseEvent<SVGPathElement | SVGElement>) => {
      e.stopPropagation();
      const featureId = e.currentTarget.dataset.id as string;
      const parentId = e.currentTarget.dataset.parentId;
      const featureRegionType = e.currentTarget.dataset
        .regionType as RegionType;

      if (
        featureId === 'svg' ||
        (parentId &&
          activeFeatureId &&
          activeFeatureId !== parentId &&
          parentId !== featureParentId)
      ) {
        onFeatureChange('', 'country');
      } else {
        onFeatureChange(featureId, featureRegionType);
      }
    },
    [activeFeatureId, featureParentId, onFeatureChange]
  );

  const handleMouseEnter = React.useCallback(
    (e: React.MouseEvent<SVGPathElement>) => {
      hideTooltipTimeout.current && clearTimeout(hideTooltipTimeout.current);
      const value = e.currentTarget.dataset.value as string;
      const name = e.currentTarget.dataset.name as string;

      setTooltip({ name, value: value ? +value : null });
    },
    []
  );

  const handleMouseLeave = React.useCallback(() => {
    hideTooltipTimeout.current && clearTimeout(hideTooltipTimeout.current);
    hideTooltipTimeout.current = window.setTimeout(
      () => setTooltip(undefined),
      500
    );
  }, []);

  const handleMouseMove = React.useCallback(
    (e: React.MouseEvent<SVGElement>) => {
      set({ tooltipX: e.clientX, tooltipY: e.clientY });
    },
    [set]
  );

  React.useEffect(() => {
    return () => {
      hideTooltipTimeout.current && clearTimeout(hideTooltipTimeout.current);
    };
  }, []);

  // ensure that we update the featureParent when activefeature is kommun
  React.useEffect(() => {
    if (activeRegionType === 'kommun') {
      setFeatureParentId(
        data.kommunData?.find((kommun) => kommun.id === activeFeatureId)?.lan_id
      );
    }
  }, [activeFeatureId, activeRegionType, data.kommunData]);

  React.useEffect(() => {
    let bounds = undefined;
    if (activeRegionType === 'country') {
      bounds = path.bounds(geoData.country.features[0]);
    } else {
      const activeFeature = geoData[activeRegionType].features.find(
        (feature: any) => feature.properties.id.toString() === activeFeatureId
      );

      if (activeFeature) {
        bounds = path.bounds(activeFeature);
      } else {
        bounds = path.bounds(geoData.country.features[0]);
      }
    }
    if (bounds) {
      const dx = bounds[1][0] - bounds[0][0];
      const dy = bounds[1][1] - bounds[0][1];
      const x = (bounds[0][0] + bounds[1][0]) / 2;
      const y = (bounds[0][1] + bounds[1][1]) / 2;

      const factor = 1;
      const scale =
        factor / Math.max(dx / w, dy / (w < 768 ? h / 1.25 : h * 1.15));
      const translate = [w / 2 - scale * x, h / 2.25 - scale * y];

      document.documentElement.style.setProperty(
        '--translateX',
        `${translate[0]}px`
      );
      document.documentElement.style.setProperty(
        '--translateY',
        `${translate[1]}px`
      );
      document.documentElement.style.setProperty('--scale', `${scale}`);
    }
  }, [activeFeatureId, activeRegionType, geoData, w, h]);

  React.useEffect(() => {
    const handleResize = debounce(() => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }, 150);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  return (
    <div className={styles.mapRoot}>
      <svg
        id="mapSVG"
        data-id="svg"
        onClick={handleFeatureClick}
        width="100%"
        height="100%"
        style={{ maxWidth: w }}
        viewBox={`0 0 ${w} ${h}`}
        preserveAspectRatio="xMidYMid meet"
        onMouseMove={handleMouseMove}
      >
        {activeCountryRegion === 'lan' &&
          (activeRegionType === 'lan' || activeRegionType === 'country') &&
          lanData && (
            <GeoShape
              path={path}
              regionType="lan"
              geoData={geoData.lan.features}
              activeFeatureId={activeFeatureId}
              activeRegionType={activeRegionType}
              activeFeatureParentId={featureParentId || ''}
              data={lanData}
              onClick={handleFeatureClick}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            />
          )}

        {(activeCountryRegion === 'region' || activeRegionType === 'region') &&
          regionData && (
            <GeoShape
              path={path}
              regionType="region"
              geoData={geoData.region.features}
              activeFeatureId={activeFeatureId}
              activeRegionType={activeRegionType}
              activeFeatureParentId={featureParentId || ''}
              data={regionData}
              onClick={handleFeatureClick}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            />
          )}

        {(activeCountryRegion === 'kommun' ||
          activeRegionType === 'lan' ||
          activeRegionType === 'kommun') &&
          kommunData && (
            <GeoShape
              path={path}
              regionType="kommun"
              geoData={geoData.kommun.features}
              activeFeatureId={activeFeatureId}
              activeRegionType={activeRegionType}
              activeFeatureParentId={featureParentId || ''}
              data={kommunData}
              onClick={handleFeatureClick}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            />
          )}
      </svg>
      <div className={styles.legend}>
        <p className={clsx(styles.legendItem, styles.darkGreen)}>40% +</p>
        <p className={clsx(styles.legendItem, styles.green)}>30-39%</p>
        <p className={clsx(styles.legendItem, styles.yellow)}>20-29%</p>
        <p className={clsx(styles.legendItem, styles.orange)}>10-19%</p>
        <p className={clsx(styles.legendItem, styles.red)}>0-9%</p>
        <p className={clsx(styles.legendItem, styles.noData)}>n/a</p>
      </div>
      {tooltip && (
        <Tooltip
          x={tooltipX?.interpolate((x: any) => `${x}px`) ?? 0}
          y={tooltipY?.interpolate((y: any) => `${y}px`) ?? 0}
          name={tooltip.name}
          value={tooltip.value}
        />
      )}
    </div>
  );
};

export default Map;
