import * as React from 'react';
import clsx from 'clsx';
import styles from './ListItemText.module.scss';

export type ListItemTextProps = React.PropsWithChildren<{
  className?: string;
  bold?: boolean;
}>;

const ListItemText = React.forwardRef<HTMLElement, ListItemTextProps>(
  function ListItemText(props, ref) {
    const { children, className, bold = false, ...other } = props;

    return (
      <span
        ref={ref}
        className={clsx(styles.root, { [styles.bold]: bold }, className)}
        {...other}
      >
        {children}
      </span>
    );
  }
);

export default ListItemText;
