import { RegionType } from 'containers/App';
import getRegionTypeName from './getRegionTypeName';

type Values = [number, number?][];
type FormattedData = {
  id: string;
  values: Values;
  name: string;
  definedValues: Values;
};

export default function getLineData(
  data: any,
  years: number[],
  regionType: RegionType
) {
  return data.reduce((obj: FormattedData, d: any) => {
    const values = [] as Values;
    const definedValues = [] as Values;
    years.forEach((year, i) => {
      // @ts-ignore
      const v = d[year];
      values.push([year, v ? +v : undefined]);
      if (v) {
        definedValues.push([year, v]);
      } else {
        definedValues.push([year, definedValues[i - 1]?.[1] ?? 0]);
      }
    });
    obj = {
      id: d.id as string,
      name: `${d.name} ${getRegionTypeName(regionType)}`,
      values,
      definedValues,
    };
    return obj;
  }, {});
}
