import * as React from 'react';
import mergeRefs from 'react-merge-refs';
import clsx from 'clsx';
import {
  animated,
  ReactSpringHook,
  useChain,
  useSpring,
  useTransition,
} from 'react-spring';
import { useSearchContext } from 'components/Search/SearchContext';
import styles from './AppBar.module.scss';

export type AppBarProps = React.HTMLAttributes<HTMLDivElement> & {
  isFocused?: boolean;
};

// TODO: initial mount the search bar is full width, needs investigating
const AppBar = React.forwardRef<HTMLDivElement, AppBarProps>(function AppBar(
  props,
  ref
) {
  const { className, children, ...rest } = props;
  const { focused } = useSearchContext();
  const rootRef = React.useRef<HTMLDivElement>();
  const [rootWidth, setRootWidth] = React.useState(0);

  const observer = React.useMemo(
    () =>
      new ResizeObserver((entries) => {
        if (entries[0]) {
          const { width } = entries[0].contentRect;
          setRootWidth(width);
        }
      }),
    []
  );

  // Build a spring and catch its ref
  const springRef = React.useRef() as React.RefObject<ReactSpringHook>;
  const springProps = useSpring({
    width: focused ? rootWidth : rootWidth - (44 + 8),
    from: { width: rootWidth - (44 + 8) },
    ref: springRef,
  });

  // Build a transition and catch its ref
  const transitionRef = React.useRef() as React.RefObject<ReactSpringHook>;
  const transitions = useTransition(focused, null, {
    initial: { opacity: 1 },
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    ref: transitionRef,
  });

  // First run the toggle hide, when it concludes run the search bar width
  useChain([transitionRef, springRef]);

  React.useLayoutEffect(() => {
    if (rootRef.current) {
      observer.observe(rootRef.current);
      return () => {
        observer.disconnect();
      };
    }
  }, [observer]);

  const firstChild = React.Children.toArray(children)[0];
  const lastChild = React.Children.toArray(children)[
    React.Children.count(children) - 1
  ];

  return (
    <div
      ref={mergeRefs([rootRef, ref])}
      className={clsx(styles.root, className)}
      {...rest}
    >
      <animated.div style={springProps}>{firstChild}</animated.div>

      {transitions.map(
        ({ item, key, props }) =>
          !item && (
            <animated.div
              key={key}
              style={{ ...props, position: 'absolute', right: 8 }}
              className={clsx(styles.toggle)}
            >
              {lastChild}
            </animated.div>
          )
      )}
    </div>
  );
});

export default AppBar;
