import * as React from 'react';
import { animated } from 'react-spring';
import { useTransition } from 'react-spring';
import IconButton from 'components/IconButton';
import Modal from 'components/Modal';
import Close from 'components/Icon/Close';
import styles from './BottomSheet.module.scss';

export type BottomSheetProps = React.PropsWithChildren<{
  open?: boolean;
  onClose?(e?: React.MouseEvent): void;
  header: string;
}>;

const BottomSheet = React.forwardRef<HTMLDivElement, BottomSheetProps>(
  function BottomSheet(props, ref) {
    const { children, onClose, open = false, header } = props;

    const transitions = useTransition(open, null, {
      from: {
        opacity: 0,
        transform: 'translate3d(0, 100%, 0)',
      },
      enter: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
      leave: { opacity: 0, transform: 'translate3d(0, 100%, 0)' },
      config: {
        tension: 380,
        friction: 40,
        clamp: true,
        velocity: 1,
      },
    });

    return (
      <>
        {transitions.map(
          ({ item, key, props: { transform, opacity } }) =>
            item && (
              <Modal
                open={open}
                key={key}
                onClose={onClose}
                ref={ref}
                backdropStyles={{ zIndex: 19 }}
                className={styles.modal}
              >
                <>
                  <animated.div
                    className={styles.root}
                    style={{ transform, opacity }}
                  >
                    <div className={styles.header}>
                      <h6>{header}</h6>
                      <IconButton onClick={onClose} edge="end">
                        <Close />
                      </IconButton>
                    </div>
                    {children}
                  </animated.div>
                </>
              </Modal>
            )
        )}
      </>
    );
  }
);

export default BottomSheet;
