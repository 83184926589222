enum Ratings {
  noData = 'noData',
  red = 'red',
  yellow = 'yellow',
  orange = 'orange',
  green = 'green',
  darkGreen = 'darkGreen',
}

export type Rating = keyof typeof Ratings;

export default function getValueRating(value: number | null): Rating {
  if (value === null) {
    return 'noData';
  } else if (value >= 0 && value <= 9) {
    return 'red';
  } else if (value > 9 && value <= 19) {
    return 'orange';
  } else if (value > 19 && value <= 29) {
    return 'yellow';
  } else if (value > 29 && value <= 39) {
    return 'green';
  } else {
    return 'darkGreen';
  }
}
