import * as React from 'react';
import clamp from 'lodash/clamp';
import { scaleLinear } from 'd3-scale';
import { useDrag } from 'react-use-gesture';
import { animated, useSpring, interpolate } from 'react-spring';
import { line, curveCardinal, area } from 'd3-shape';
import { bisectLeft } from 'd3-array';
import clsx from 'clsx';
import { ParsedDataset, RegionType } from 'containers/App';
import Tooltip from 'blocks/Tooltip';
import getRange from 'utils/getRange';
import getLineData from 'utils/getLineData';
import getAccumulatedData from 'utils/getAccumulatedData';
import getAverageLineData from 'utils/getAverageLineData';
import getMinMaxAreaData from 'utils/getMinMaxAreaData';
import getUnitName from 'utils/getUnitName';
import styles from './Graph.module.scss';

export type GraphProps = {
  data: ParsedDataset;
  years: string[];
  activeRegionType: RegionType;
  activeDataSet: string;
  activeFeatureId?: string;
};

const yRange = getRange(0, 100, 10);
const deviceBreakpoint = '(min-width:768px)';
const appSidePanelBreakpoint = '(min-width:1080px)';
const offset = 100;

const Graph: React.FC<GraphProps> = (props) => {
  const {
    data,
    years,
    activeRegionType,
    activeFeatureId,
    activeDataSet,
  } = props;
  const hideTooltipTimeout = React.useRef(0);
  const [matches, setMatches] = React.useState(
    window?.matchMedia(deviceBreakpoint).matches
  );
  const [matchesSidePanel, setMatchesSidePanel] = React.useState(
    window?.matchMedia(appSidePanelBreakpoint).matches
  );
  const graphYears = React.useMemo(
    (): number[] => new Array(...years.map((y) => +y)).reverse(),
    [years]
  );
  const [tooltip, setTooltip] = React.useState<
    { name: string; value: number | null; bisect: number } | undefined
  >();
  const h = React.useMemo(() => (matches ? 450 : 300), [matches]);
  const w = React.useMemo(
    () => clamp(graphYears.length * 100, matches ? 500 : 300, 800),
    [matches, graphYears]
  );

  React.useEffect(() => {
    const handleDeviceChange = (e: MediaQueryListEvent) => {
      setMatches(e.matches);
    };
    const handleSidePanelChange = (e: MediaQueryListEvent) => {
      setMatchesSidePanel(e.matches);
    };

    const matchMediaDevice = window.matchMedia(deviceBreakpoint);
    const matchMediaSidePanel = window.matchMedia(deviceBreakpoint);

    try {
      matchMediaDevice.addEventListener('change', handleDeviceChange);
      matchMediaSidePanel.addEventListener('change', handleSidePanelChange);
      return () => {
        matchMediaDevice.removeEventListener('change', handleDeviceChange);
        matchMediaSidePanel.removeEventListener(
          'change',
          handleSidePanelChange
        );
      };
    } catch (e) {
      // NOTE: this catch is to support older versions of Safari
      matchMediaDevice.addListener(handleDeviceChange);
      matchMediaSidePanel.addListener(handleSidePanelChange);
      return () => {
        matchMediaDevice.removeListener(handleDeviceChange);
        matchMediaSidePanel.removeListener(handleSidePanelChange);
      };
    }
  }, []);

  const [{ springX }, set] = useSpring(() => ({
    springX: 0,
    config: { precision: 1 },
  }));

  const [{ tooltipX, tooltipY }, setTooltipPosition] = useSpring(() => ({
    tooltipX: 0,
    tooltipY: 0,
    config: { precision: 1 },
  }));

  const visibleView =
    window.innerWidth - (matchesSidePanel ? 532 : matches ? 432 : 0);
  const leftBounds = clamp(-(w - (visibleView - offset * 0.75)), 0);

  const bind = useDrag(({ movement: [x] }) => set({ springX: x }), {
    axis: 'x',
    bounds: {
      left: leftBounds,
      right: 0,
    },
    initial: () => [(springX && (springX.getValue() as number)) ?? 0, 0],
  });

  const areaData = React.useMemo(() => {
    if (data.kommunData) {
      const values = getMinMaxAreaData(
        getAccumulatedData(data.kommunData, graphYears)
      );
      return { id: 'SE_Range', name: 'Utbredning Sveriges kommuner', values };
    } else if (data.countryData) {
      const values = getMinMaxAreaData(
        getAccumulatedData(data.countryData, graphYears)
      );
      return {
        id: '2',
        name: `Utbredning - ${data.countryData[0]?.type}`,
        values,
      };
    }
    return undefined;
  }, [data.countryData, data.kommunData, graphYears]);

  const lineData = React.useMemo(() => {
    if (data.kommunData && (data.lanData || data.regionData)) {
      if (activeRegionType === 'country') {
        if (data.countryData) {
          const values = getLineData(data.countryData, graphYears, 'country');
          return { id: 'SE', ...values, name: 'Sverige' };
        }

        const values = getAverageLineData(
          getAccumulatedData(data.kommunData, graphYears)
        );

        return { id: 'SE', name: 'Sverige', values };
      } else if (activeRegionType === 'lan') {
        const dataset = data?.lanData?.filter((d) => d.id === activeFeatureId);
        return getLineData(dataset, graphYears, activeRegionType);
      } else if (activeRegionType === 'region') {
        const dataset = data?.regionData?.filter(
          (d) => d.id === activeFeatureId
        );
        return getLineData(dataset, graphYears, activeRegionType);
      } else if (activeRegionType === 'kommun') {
        const dataset = data.kommunData.filter((d) => d.id === activeFeatureId);
        return getLineData(dataset, graphYears, activeRegionType);
      }
    } else if (data.countryData) {
      const values = getAverageLineData(
        getAccumulatedData(data.countryData, graphYears)
      );
      return {
        id: '3',
        name: `Medelvärde - ${data.countryData[0]?.type}`,
        values,
      };
    }

    return undefined;
  }, [data, activeRegionType, activeFeatureId, graphYears]);

  const scaleX = React.useMemo(
    () =>
      scaleLinear()
        .domain([graphYears[0], graphYears[graphYears.length - 1]])
        .range([0, w]),
    [graphYears, w]
  );

  const scaleY = React.useMemo(
    () =>
      scaleLinear()
        .domain([yRange[0], yRange[yRange.length - 1]])
        .range([h, 0]),
    []
  );

  const _getLinePath = () => {
    return line()
      .x((d) => scaleX(d[0]))
      .y((d) => scaleY(d[1]))
      .curve(curveCardinal.tension(0.8))
      .defined((d) => !isNaN(d[1]));
  };

  const _getAreaPath = () => {
    return (
      area()
        .x((d) => scaleX(d[0]))
        .y0((d) => scaleY(d[1]))
        // @ts-ignore
        .y1((d) => scaleY(d[2]))
        .curve(curveCardinal.tension(0.8))
    );
  };

  const getLinePath = _getLinePath();
  const getAreaPath = _getAreaPath();

  const handleMouseMove = React.useCallback(
    (e: React.MouseEvent<SVGGElement>) => {
      hideTooltipTimeout.current && clearTimeout(hideTooltipTimeout.current);
      if (lineData?.values) {
        // @ts-ignore
        const { left } = e.target.getBoundingClientRect();
        const x = e.clientX - left;
        const xData = `${Math.round(scaleX.invert(x))}`;
        const bisect = bisectLeft(
          lineData.values.map((d: [number, number]) => d[0]),
          xData
        );
        const activeLineValue = lineData.values[bisect][1];
        setTooltipPosition({ tooltipX: e.clientX, tooltipY: e.clientY });
        setTooltip({
          name: lineData?.name ?? '',
          value: activeLineValue ? +activeLineValue : null,
          bisect,
        });
      }
    },
    [lineData, scaleX, setTooltipPosition]
  );

  const handleMouseLeave = React.useCallback(() => {
    hideTooltipTimeout.current && clearTimeout(hideTooltipTimeout.current);
    hideTooltipTimeout.current = window.setTimeout(
      () => setTooltip(undefined),
      500
    );
  }, []);

  React.useEffect(() => {
    return () => {
      hideTooltipTimeout.current && clearTimeout(hideTooltipTimeout.current);
    };
  }, []);

  React.useEffect(() => {
    set({ springX: 0 });
  }, [activeDataSet, activeFeatureId, set]);

  return (
    <div className={styles.graphRoot} {...bind()}>
      <svg
        style={{ cursor: 'grab' }}
        width={w + offset}
        height={h + offset}
        id="graphSVG"
        className={styles.svg}
      >
        <g transform={`translate(${offset / 2}, ${offset / 2})`}>
          <animated.g
            style={{
              transform: interpolate(
                [springX] as any,
                (x) => `translate(${x}px, 0)`
              ),
            }}
          >
            {areaData && (
              <path
                key={areaData.id}
                className={clsx(styles.area)}
                // @ts-ignore
                d={getAreaPath(areaData.values)}
                fill="none"
                strokeWidth="1.5"
                strokeLinecap="round"
                onMouseMove={handleMouseMove}
                onMouseLeave={handleMouseLeave}
              />
            )}
            {lineData?.definedValues && (
              <path
                key={`${lineData.id}-defined`}
                className={clsx(styles.line, styles.defined)}
                d={getLinePath(lineData.definedValues) as string}
                fill="none"
                strokeWidth="2"
                strokeLinecap="round"
              />
            )}
            {lineData && (
              <path
                key={lineData.id}
                className={clsx(styles.line)}
                d={getLinePath(lineData.values) as string}
              />
            )}
            <g className="xAxis">
              <path
                className={clsx(styles.axis, styles.x)}
                d={getLinePath(graphYears.map((year) => [year, 0])) as string}
              />
              {graphYears.map((year) => {
                const x = scaleX(year);
                const y = scaleY(yRange[0]);

                return (
                  <g
                    key={year}
                    className={styles.tickX}
                    transform={`translate(${x}, ${y})`}
                  >
                    <line className={styles.tick} x={x} y={y} y2={4} />
                    <text
                      className={clsx(styles.text, styles.textX)}
                      dy="2.5em"
                    >
                      {year}
                    </text>
                  </g>
                );
              })}
              {tooltip && (
                <path
                  className={clsx(styles.axis, styles.y, styles.indicator)}
                  d={
                    getLinePath(
                      yRange.map((y) => [graphYears[tooltip.bisect], y])
                    ) as string
                  }
                />
              )}
            </g>
          </animated.g>

          <g className="yAxis">
            <path
              className={clsx(styles.axis, styles.background)}
              d={getLinePath(yRange.map((y) => [graphYears[0], y])) as string}
            />
            <path
              className={clsx(styles.axis, styles.y)}
              d={getLinePath(yRange.map((y) => [graphYears[0], y])) as string}
            />

            {yRange.map((value, i) => {
              const x = scaleX(graphYears[0]);
              const y = scaleY(value);
              return (
                <g
                  key={value}
                  className={styles.tickY}
                  transform={`translate(${x}, ${y})`}
                >
                  <path
                    className={styles.tick}
                    d={
                      getLinePath([
                        [graphYears[0], value],
                        [graphYears[graphYears.length - 1], value],
                      ]) as string
                    }
                  />
                  {i !== 0 && (
                    <text className={clsx(styles.text, styles.textY)}>
                      {`${value}${getUnitName(lineData.unit)}`}
                    </text>
                  )}
                </g>
              );
            })}
          </g>
        </g>
      </svg>
      <div className={styles.legend}>
        <div className={styles.legendItem}>
          <div className={styles.activeLine} />
          <p>{lineData?.name}</p>
        </div>
        <div className={styles.legendItem}>
          <div className={styles.referenceArea} />
          <p>{areaData?.name}</p>
        </div>
      </div>
      {tooltip && (
        <Tooltip
          x={tooltipX?.interpolate((x: any) => `${x}px`) ?? 0}
          y={tooltipY?.interpolate((y: any) => `${y}px`) ?? 0}
          name={tooltip.name}
          value={tooltip.value}
        />
      )}
    </div>
  );
};

export default Graph;
