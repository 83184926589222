import * as React from 'react';
import clsx from 'clsx';
import styles from './IconButton.module.scss';

export type IconButtonProps = React.PropsWithChildren<
  React.HTMLAttributes<HTMLButtonElement> & {
    className?: string;
    edge?: 'start' | 'end';
  }
>;

const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  function IconButton(props, ref) {
    const { children, onClick, className, edge, ...other } = props;
    return (
      <button
        ref={ref}
        className={clsx(
          styles.root,
          {
            [styles.edgeEnd]: edge === 'end',
            [styles.edgeStart]: edge === 'start',
          },
          className
        )}
        onClick={onClick}
        {...other}
      >
        {children}
      </button>
    );
  }
);

export default IconButton;
