import * as React from 'react';
import clsx from 'clsx';
import List from 'components/List';
import ListItem from 'components/ListItem';
import MapIcon from 'components/Icon/Map';
import ListIcon from 'components/Icon/List';
import BarsIcon from 'components/Icon/Bars';
import styles from './DataVisualisationToggle.module.scss';
import { VisualisationMode } from 'containers/App';

export type VisualisationIcon = 'map' | 'list' | 'bars';
export type DataVisualisationToggleData = {
  id: VisualisationMode;
  title: string;
  icon: VisualisationIcon;
  disabled: boolean;
};

export type DataVisualisationToggleProps = {
  activeVisualisation: VisualisationMode;
  onChange?(id: VisualisationMode): void;
  data: DataVisualisationToggleData[];
  hideHeader?: boolean;
};

const icons: Record<VisualisationIcon, React.ReactNode> = {
  map: <MapIcon />,
  list: <ListIcon />,
  bars: <BarsIcon />,
};

const DataVisualisationToggle: React.FC<DataVisualisationToggleProps> = (
  props
) => {
  const {
    activeVisualisation,
    data = [],
    onChange,
    hideHeader = false,
  } = props;

  const handleChange = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
      const dataKey =
        (e.currentTarget as HTMLDivElement).dataset.value ||
        activeVisualisation;

      onChange?.(dataKey as VisualisationMode);
    },
    [activeVisualisation, onChange]
  );

  return (
    <div className={styles.root}>
      {!hideHeader && (
        <div className={styles.header}>
          <h6>Datavisualisering</h6>
        </div>
      )}
      <List disablePadding>
        {data.map((item) => (
          <ListItem
            key={item.id}
            onClick={handleChange}
            data-value={item.id}
            variant="large"
            component="button"
            startIcon={icons[item.icon]}
            disabled={item.disabled}
            className={clsx({
              [styles.active]: item.id === activeVisualisation,
            })}
          >
            {item.title}
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default DataVisualisationToggle;
