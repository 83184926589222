import { RegionType } from 'containers/App';

export default function getRegionTypeName(type: RegionType) {
  switch (type) {
    case 'lan':
      return 'län';
    case 'region':
      return ''; // NOTE: return empty string for region as the term Region is in the data
    default:
      return 'kommun';
  }
}
