import * as React from 'react';
import clsx from 'clsx';
import mergeRefs from 'react-merge-refs';
import Input from 'components/Input';
import Search from 'components/Icon/Search';
import Close from 'components/Icon/Close';
import IconButton from 'components/IconButton';
import { useSearchContext } from 'components/Search/SearchContext';
import styles from './SearchBar.module.scss';

export type SearchBarProps = React.HTMLAttributes<HTMLDivElement> & {
  value?: string;
  disabled?: boolean;
  onClear?(): void;
};

const SearchBar = React.forwardRef<HTMLDivElement, SearchBarProps>(
  function SearchBar(props, ref) {
    const { className, disabled = false, onClear, ...other } = props;
    const {
      focused,
      setFocused,
      value,
      setValue,
      searchBarRef,
    } = useSearchContext();
    const inputRef = React.useRef<HTMLInputElement>();

    const handleChange = React.useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.currentTarget;
        setValue(value);
      },
      [setValue]
    );

    const handleClick = () => {
      if (!disabled) {
        setFocused(true);
      }
    };

    const handleClear = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (value.length === 0) {
        setFocused(false);
      } else {
        inputRef.current?.focus();
        setFocused(true);
        setValue('');
        onClear?.();
      }
    };

    const showEndIcon = focused || (!focused && value.length > 0);

    return (
      <div
        ref={mergeRefs([ref, searchBarRef])}
        className={clsx(
          styles.root,
          { [styles.disabled]: disabled },
          className
        )}
        onClick={handleClick}
      >
        <Search fill="currentColor" className={styles.searchIcon} />
        <Input
          onChange={handleChange}
          ref={inputRef as React.MutableRefObject<HTMLInputElement>}
          {...(showEndIcon && {
            endIcon: (
              <IconButton onClick={handleClear} edge="end">
                <Close fill="currentColor" />
              </IconButton>
            ),
          })}
          type="search"
          disabled={disabled}
          value={value}
          {...other}
        />
      </div>
    );
  }
);

export default SearchBar;
