import * as React from 'react';
import { animated, useTransition } from 'react-spring';
import * as ReactDOM from 'react-dom';
import clsx from 'clsx';
import styles from './Modal.module.scss';

export type ModalProps = React.PropsWithChildren<{
  open: boolean;
  hideBackdrop?: boolean;
  disableBodyScroll?: boolean;
  onClose?: () => void;
  backdropStyles?: Record<string, any>;
  className?: string;
}>;

const Modal = React.forwardRef<HTMLDivElement, ModalProps>(function Modal(
  props,
  ref
) {
  const {
    children,
    open,
    hideBackdrop = false,
    onClose,
    disableBodyScroll,
    backdropStyles = {},
    className,
  } = props;
  const node = React.useRef(
    document ? document.getElementById('modal') : undefined
  );

  const transitions = useTransition(open, null, {
    from: {
      opacity: 0,
    },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      tension: 380,
      friction: 40,
      clamp: true,
      velocity: 1,
    },
  });

  React.useEffect(() => {
    if (disableBodyScroll) {
      if (open) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }

      return () => {
        document.body.style.overflow = 'auto';
      };
    }
  }, [disableBodyScroll, open]);

  if (!node.current) {
    return null;
  }

  return ReactDOM.createPortal(
    <>
      {transitions.map(
        ({ item, key, props: { opacity } }) =>
          item && (
            <div key={key} className={clsx(styles.root, className)} ref={ref}>
              <animated.div
                className={styles.backdrop}
                style={{
                  opacity: !hideBackdrop ? opacity : 0,
                  ...backdropStyles,
                }}
                onClick={onClose}
              />

              {children}
            </div>
          )
      )}
    </>,
    node.current
  );
});

export default Modal;
