import * as React from 'react';
import clsx from 'clsx';
import styles from './ListItem.module.scss';

enum ListItemVariants {
  default = 'default',
  large = 'large',
  condensed = 'condensed',
}

export type ListItemProps = React.PropsWithChildren<{
  component?: 'div' | 'button';
  variant?: keyof typeof ListItemVariants;
  className?: string;
  underline?: boolean;
  showListStyles?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  classes?: Record<string, string>;
  disabled?: boolean;
  onClick?(e?: React.MouseEvent<HTMLDivElement | HTMLButtonElement>): void;
}>;

const ListItem = React.forwardRef<HTMLLIElement, ListItemProps>(
  function ListItem(props, ref) {
    const {
      children,
      classes,
      className,
      variant = 'default',
      component: Component = 'div',
      underline = false,
      showListStyles = false,
      disabled = false,
      startIcon,
      endIcon,
      onClick,
      ...other
    } = props;

    return (
      <li
        ref={ref}
        className={clsx(
          styles.root,
          {
            [styles.underline]: underline,
            [styles.showListStyles]: showListStyles,
          },
          classes?.root,
          className
        )}
      >
        <Component
          onClick={onClick}
          disabled={disabled}
          className={clsx(styles.childrenRoot, styles[variant], {
            [styles.button]: Component === 'button',
          })}
          {...other}
        >
          {startIcon && (
            <span
              className={clsx(
                styles.icon,
                styles.startIcon,
                classes?.startIcon
              )}
            >
              {startIcon}
            </span>
          )}
          {children}
          {endIcon && (
            <span
              className={clsx(styles.icon, styles.endIcon, classes?.endIcon)}
            >
              {endIcon}
            </span>
          )}
        </Component>
      </li>
    );
  }
);

export default ListItem;
