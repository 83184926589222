import * as React from 'react';
import ScrollableList from 'components/ScrollableList';
import ExpandMore from 'components/Icon/ExpandMore';
import ExpandLess from 'components/Icon/ExpandLess';
import BlockContent from '@sanity/block-content-to-react';
import Card from 'components/Card';
import DataVisualisationToggle, {
  DataVisualisationToggleProps,
} from 'blocks/DataVisualisationToggle';
import styles from './DatasetToggleDesktop.module.scss';
import MenuDialog from 'components/MenuDialog';
import List from 'components/List';
import ListItem from 'components/ListItem';
import ListItemText from 'components/ListItemText';
import clsx from 'clsx';
import IconButton from 'components/IconButton';

type Dataset = Record<
  string,
  {
    id: string;
    title: string;
    description: string;
  }
>;

export type DatasetToggleDesktopProps = Omit<
  DataVisualisationToggleProps,
  'onChange'
> & {
  activeDatasetId: string;
  datasets: Dataset;
  onChange?(id: string): void;
  onVisualisationChange: DatasetToggleDesktopProps['onChange'];
};

const DatasetToggleDesktop: React.FC<DatasetToggleDesktopProps> = (props) => {
  const {
    activeDatasetId,
    datasets,
    onChange,
    ...visualisationToggleProps
  } = props;
  const descriptionRef = React.useRef<HTMLDivElement>(null);
  const datasetRef = React.useRef<HTMLDivElement>(null);
  const [datasetListOpen, setDatasetListOpen] = React.useState(false);

  const handleDatasetSelect = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const dataKey = e.currentTarget.dataset.value || activeDatasetId;
      setDatasetListOpen(false);

      onChange?.(dataKey);

      descriptionRef.current?.scrollTo(0, 0);
    },
    [activeDatasetId, onChange]
  );

  const openDatalist = React.useCallback(() => {
    setDatasetListOpen(true);
  }, []);

  return (
    <div className={styles.root}>
      <div ref={datasetRef}>
        <p className={styles.panelHeader}>{`Välj data (${
          Object.keys(datasets).length
        })`}</p>

        <div className={styles.datasetList}>
          <Card
            key={datasets[activeDatasetId].id}
            data-value={datasets[activeDatasetId].id}
            onClick={openDatalist}
            text={datasets[activeDatasetId].title}
            variant="active"
          >
            <IconButton
              className={clsx(
                styles.iconButton,
                datasetListOpen && styles.open
              )}
              onClick={openDatalist}
            >
              <ExpandMore fill="currentColor" />
            </IconButton>
          </Card>
        </div>

        <MenuDialog
          anchorEl={datasetRef}
          open={datasetListOpen}
          verticalPosition="bottom"
          hideBackdrop
          fullWidth
          onClose={() => setDatasetListOpen(false)}
        >
          <List disablePadding>
            {Object.values(datasets).map((data) => (
              <ListItem
                key={data.id}
                underline
                component="button"
                data-value={data.id}
                onClick={handleDatasetSelect}
                className={
                  data.id === activeDatasetId ? styles.listItemActive : ''
                }
              >
                <ListItemText>{data.title}</ListItemText>
              </ListItem>
            ))}
          </List>
        </MenuDialog>
      </div>
      <div className={styles.description} ref={descriptionRef}>
        <BlockContent blocks={datasets[activeDatasetId].description} />
      </div>
      <div className={styles.footer}>
        <DataVisualisationToggle
          {...visualisationToggleProps}
          onChange={props.onVisualisationChange}
        />
      </div>
    </div>
  );
};

export default DatasetToggleDesktop;
