import { Metric } from 'web-vitals';

export default function sendToAnalytics({ id, name, value }: Metric) {
  // @ts-ignore
  window.dataLayer.push({
    event_category: 'Web Vitals',
    event: 'web-vitals',
    event_action: name,
    event_value: Math.round(name === 'CLS' ? value * 1000 : value),
    event_label: id,
  });
}
