import * as React from 'react';
import clsx from 'clsx';
import List from 'components/List';
import ListItem from 'components/ListItem';
import IconButton, { IconButtonProps } from 'components/IconButton';
import CloseIcon from 'components/Icon/Close';
import ListItemText from 'components/ListItemText';
import { SearchData } from 'components/Search/SearchContext';
import styles from './Results.module.scss';
import getRegionTypeName from 'utils/getRegionTypeName';

export type SearchResultsProps = {
  results: SearchData[];
  onRemove?(e: React.MouseEvent<HTMLButtonElement>): void;
  onClick(e: React.MouseEvent<HTMLButtonElement>): void;
  IconButtonProps?: IconButtonProps;
  header: string;
};

const SearchResults: React.FC<SearchResultsProps> = (props) => {
  const {
    results,
    onRemove,
    onClick,
    header,
    IconButtonProps,
    ...other
  } = props;

  return (
    <List
      disablePadding
      subheader={
        <div className={clsx(styles.subheader)}>
          <h6>{header}</h6>
        </div>
      }
      {...other}
    >
      {results.map(({ name, type, id }, i) => (
        <ListItem
          key={id}
          underline
          component="button"
          onClick={onClick}
          data-id={id}
          className={styles.listItem}
          {...(onRemove && {
            endIcon: (
              <IconButton
                data-index={i}
                onClick={onRemove}
                {...IconButtonProps}
              >
                <CloseIcon fill="currentColor" />
              </IconButton>
            ),
          })}
        >
          <ListItemText>
            {name} {getRegionTypeName(type)}
          </ListItemText>
        </ListItem>
      ))}
    </List>
  );
};

export default SearchResults;
