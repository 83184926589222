import clsx from 'clsx';
import * as React from 'react';
import { animated, useTransition } from 'react-spring';
import Modal, { ModalProps } from '../Modal';
import styles from './MenuDialog.module.scss';

export type MenuDialogProps = ModalProps & {
  anchorEl?: React.RefObject<HTMLElement | undefined>;
  horizontalPosition?: string | number;
  verticalPosition?: string | number;
  fullWidth?: boolean;
  style?: Record<string, string>;
  className?: string;
};

function getOffsetLeft(width: number, horizontal: string | number) {
  let offset = 0;

  if (typeof horizontal === 'number') {
    offset = horizontal;
  } else if (horizontal === 'center') {
    offset = width / 2;
  } else if (horizontal === 'right') {
    offset = width;
  }

  return offset;
}

function getOffsetTop(height: number, vertical: string | number) {
  let offset = 0;

  if (typeof vertical === 'number') {
    offset = vertical;
  } else if (vertical === 'center') {
    offset = height / 2;
  } else if (vertical === 'bottom') {
    offset = height;
  }

  return offset;
}

const MenuDialog = React.forwardRef<HTMLDivElement, MenuDialogProps>(
  function MenuDialog(props, ref) {
    const {
      anchorEl,
      horizontalPosition = 'left',
      verticalPosition = 'top ',
      children,
      fullWidth = false,
      style,
      className,
      ...modalProps
    } = props;

    const transitions = useTransition(modalProps.open, null, {
      from: {
        opacity: 0,
      },
      enter: { opacity: 1 },
      leave: { opacity: 0 },
      config: { tension: 120, friction: 16, clamp: true },
    });

    const getAnchorOffset = React.useCallback(() => {
      if (anchorEl?.current) {
        const anchorRect = anchorEl?.current?.getBoundingClientRect();

        return {
          top:
            anchorRect?.top +
            getOffsetTop(anchorRect?.height, verticalPosition),
          left:
            anchorRect?.left +
            getOffsetLeft(anchorRect?.width, horizontalPosition),
        };
      }
    }, [anchorEl, horizontalPosition, verticalPosition]);

    const offset = getAnchorOffset();

    return (
      <Modal {...modalProps} ref={ref}>
        {transitions.map(
          ({ item, key, props: { opacity } }) =>
            item && (
              <animated.div
                key={key}
                className={clsx(styles.root, className)}
                style={{
                  top: offset?.top,
                  left: offset?.left,
                  opacity,
                  ...(fullWidth && {
                    width:
                      anchorEl?.current?.getBoundingClientRect().width ??
                      'auto',
                  }),
                  ...style,
                }}
              >
                {children}
              </animated.div>
            )
        )}
      </Modal>
    );
  }
);

export default MenuDialog;
