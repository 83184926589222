import * as React from 'react';
import MenuDialog from 'components/MenuDialog';
import { useSearchContext } from 'components/Search/SearchContext';
import Results from './partials/Results';
import SearchResultsFullPage from './partials/SearchResultsFullPage';
import styles from './SearchResults.module.scss';

const mediaQuery = '(min-width:768px)';

const SearchResults: React.FC = () => {
  const {
    results,
    data,
    focused,
    value,
    setFocused,
    searchHistory,
    searchBarRef,
    onSelect,
    onRemove,
  } = useSearchContext();
  const [matches, setMatches] = React.useState(
    window?.matchMedia(mediaQuery).matches
  );

  const handleClose = React.useCallback(() => {
    setFocused(false);
  }, [setFocused]);

  React.useEffect(() => {
    const handleChange = (e: MediaQueryListEvent) => {
      setMatches(e.matches);
    };

    const matchMedia = window.matchMedia(mediaQuery);

    try {
      matchMedia.addEventListener('change', handleChange);
      return () => matchMedia.removeEventListener('change', handleChange);
    } catch (e) {
      // NOTE: this catch is to support older versions of Safari
      matchMedia.addListener(handleChange);
      return () => matchMedia.removeListener(handleChange);
    }
  }, []);

  return (
    <>
      {searchBarRef.current && matches && (
        <MenuDialog
          anchorEl={searchBarRef}
          open={focused}
          verticalPosition="bottom"
          hideBackdrop
          fullWidth
          onClose={handleClose}
          className={styles.menuDialog}
        >
          {value.length <= 1 && searchHistory.length !== 0 && (
            <Results
              results={searchHistory}
              onClick={onSelect}
              onRemove={onRemove}
              header="Senast"
            />
          )}

          {value.length > 1 && results.length !== 0 && (
            <Results results={results} onClick={onSelect} header="Resultat" />
          )}

          {value.length === 0 && searchHistory.length === 0 && (
            <Results results={data} onClick={onSelect} header="Resultat" />
          )}
        </MenuDialog>
      )}

      {!matches && (
        <SearchResultsFullPage open={focused} handleClick={onSelect}>
          {value.length <= 1 && searchHistory.length !== 0 && (
            <Results
              results={searchHistory}
              onClick={onSelect}
              onRemove={onRemove}
              header="Senast"
            />
          )}

          {value.length > 1 && results.length !== 0 && (
            <Results results={results} onClick={onSelect} header="Resultat" />
          )}

          {value.length === 0 && searchHistory.length === 0 && (
            <Results results={data} onClick={onSelect} header="Resultat" />
          )}
        </SearchResultsFullPage>
      )}
    </>
  );
};

export default SearchResults;
