import * as React from 'react';

import Layers from 'components/Icon/Layers';
import styles from './DataLayerToggle.module.scss';

export type DataLayerToggleProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

const DataLayerToggle: React.FC<DataLayerToggleProps> = (props) => {
  return (
    <button className={styles.toggleRoot} {...props}>
      <Layers fill="currentColor" width={28} height={28} />
    </button>
  );
};

export default DataLayerToggle;
