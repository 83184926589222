import * as React from 'react';
import { RegionType } from 'containers/App';
import clsx from 'clsx';
import getValueRating from 'utils/getValueRating';
import getRegionTypeName from 'utils/getRegionTypeName';
import styles from '../Map.module.scss';

type Feature = {
  properties: any; // TODO: update
};

export type GeoShapeProps = {
  geoData: Feature[];
  data: Record<string, { value?: number; parentId?: string; name?: string }>;
  regionType: RegionType;
  activeFeatureId?: string;
  activeRegionType: RegionType;
  activeFeatureParentId: string;
  onClick(e: React.MouseEvent<SVGPathElement>): void;
  onMouseEnter(e: React.MouseEvent<SVGPathElement>): void;
  onMouseLeave(): void;
  path: any; // TODO: update
};

const GeoShape: React.FC<GeoShapeProps> = (props) => {
  const {
    geoData,
    data,
    path,
    regionType,
    activeFeatureId,
    activeRegionType,
    activeFeatureParentId,
    onClick,
    onMouseEnter,
    onMouseLeave,
  } = props;

  const isFeatureHidden = (id: string) => {
    if ((!id && !activeFeatureId) || activeRegionType === 'country') {
      return false;
    }

    if (regionType === 'lan') {
      if (activeRegionType === 'lan') {
        return id === activeFeatureId;
      } else if (activeRegionType === 'kommun') {
        return id === activeFeatureParentId;
      } else {
        return false;
      }
    } else if (regionType === 'region') {
      if (activeRegionType === 'region') {
        return id === activeFeatureId;
      } else if (activeRegionType === 'kommun') {
        return id === activeFeatureParentId;
      } else {
        return false;
      }
    } else {
      if (activeRegionType === 'lan') {
        return id !== activeFeatureId;
      } else if (activeRegionType === 'kommun') {
        if (activeFeatureParentId) {
          return id !== activeFeatureParentId;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }
  };

  return (
    <g id={`${regionType}Shapes`} className={styles.g}>
      {geoData.map((feature) => {
        const featureData = data[feature.properties.id];

        const fill =
          featureData?.value !== undefined
            ? getValueRating(featureData.value)
            : 'noData';

        const highlight =
          !activeFeatureId ||
          featureData?.parentId === activeFeatureId ||
          feature.properties.id.toString() === activeFeatureId;

        const hidden = isFeatureHidden(
          regionType === 'lan'
            ? feature.properties.id.toString()
            : featureData?.parentId
        );

        return (
          <path
            key={feature.properties.id + Math.random()}
            data-id={feature.properties.id}
            data-parent-id={featureData?.parentId}
            data-name={`${featureData?.name} ${getRegionTypeName(regionType)}`}
            data-value={featureData?.value}
            data-region-type={regionType}
            d={path(feature)}
            className={clsx(
              styles.geoShape,
              styles[fill],
              highlight && styles.highlight,
              hidden && styles.hidden
            )}
            stroke="#fff"
            strokeWidth={activeRegionType !== 'country' ? '.1px' : '.4px'}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          />
        );
      })}
    </g>
  );
};

export default GeoShape;
