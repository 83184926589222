import clsx from 'clsx';
import * as React from 'react';
import styles from './Button.module.scss';

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  endAdornment?: React.ReactNode;
  startAdornment?: React.ReactNode;
};

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  props,
  ref
) {
  const { children, className, startAdornment, endAdornment, ...rest } = props;

  return (
    <button ref={ref} className={clsx(styles.root, className)} {...rest}>
      {startAdornment && (
        <span className={clsx(styles.adornment, styles.start)}>
          {startAdornment}
        </span>
      )}
      <span>{children}</span>
      {endAdornment && (
        <span className={clsx(styles.adornment, styles.end)}>
          {endAdornment}
        </span>
      )}
    </button>
  );
});

export default Button;
