import * as React from 'react';
import { useSpring, animated } from 'react-spring';
import styles from './ScrollableList.module.scss';

export type ScrollableListProps = {
  activeChildIndex?: number;
};

const defaultAnimationConfig = {
  from: { scrollLeft: 0 },
  to: { scrollLeft: 0 },
  reset: true,
  config: { tension: 120, friction: 16, clamp: true },
};

function getChildrenPositions(
  node: Element
): { positions: Array<{ left: number; right: number }> } {
  const children = Array.from(node.children);
  if (children.length <= 1) {
    const child = children[0];
    if (child.hasChildNodes()) {
      return getChildrenPositions(child);
    }
  }

  const calculatedPositions = children.reduce(
    (obj: { positions: Array<{ left: number; right: number }> }, child) => {
      const { left, right } = child.getBoundingClientRect();

      obj.positions = [...obj.positions, { left, right }];

      return obj;
    },
    { positions: [] }
  );

  return calculatedPositions;
}

const ScrollableList: React.FC<ScrollableListProps> = (props) => {
  const { children, activeChildIndex = 0 } = props;
  const visibleView = React.useRef<HTMLDivElement>(null);
  const [styleProps, set] = useSpring(() => defaultAnimationConfig);

  const resetScroll = React.useCallback(() => {
    set({
      ...defaultAnimationConfig,
      from: {
        scrollLeft:
          visibleView && visibleView.current
            ? visibleView.current.scrollLeft
            : 0,
      },
      to: { scrollLeft: 0 },
    });
  }, [set]);

  React.useEffect(() => {
    resetScroll();
  }, []);

  React.useEffect(() => {
    if (activeChildIndex === -1 || !visibleView || !visibleView.current) return;

    const { positions } = getChildrenPositions(visibleView.current);

    if (positions.length < 1 || !positions[activeChildIndex]) return;

    const { left, right } = positions[activeChildIndex];
    const visibleBoundary = visibleView.current.getBoundingClientRect();

    if (Math.floor(left) < Math.floor(visibleBoundary.left)) {
      const leftDiff = left - visibleBoundary.left - 20;
      set({
        ...defaultAnimationConfig,
        from: { scrollLeft: visibleView.current.scrollLeft },
        to: { scrollLeft: visibleView.current.scrollLeft + leftDiff },
      });
      return;
    }

    if (Math.floor(right) > Math.floor(visibleBoundary.right)) {
      const rightDiff = right - visibleBoundary.right + 20;
      set({
        ...defaultAnimationConfig,
        from: { scrollLeft: visibleView.current.scrollLeft },
        to: { scrollLeft: visibleView.current.scrollLeft + rightDiff },
      });
    }
  }, [activeChildIndex, set]);

  return (
    <div className={styles.scrollableListRoot}>
      <animated.div
        className={styles.visibleView}
        ref={visibleView}
        {...styleProps}
      >
        <div className={styles.innerRoot}>{children}</div>
      </animated.div>
    </div>
  );
};

export default ScrollableList;
