import clsx from 'clsx';
import * as React from 'react';
import styles from './ButtonGroup.module.scss';

export type ButtonGroupProps = React.PropsWithChildren<{ className?: string }>;

const ButtonGroup: React.FC<ButtonGroupProps> = (props) => {
  const { children, className, ...rest } = props;
  return (
    <div className={clsx(styles.root, className)} {...rest}>
      {children}
    </div>
  );
};

export default ButtonGroup;
