import * as React from 'react';
import clsx from 'clsx';
import { animated, useSpring, interpolate } from 'react-spring';
import TemporalFilter from '../TemporalFilter';
import styles from './TemporalToggleList.module.scss';

export type TemporalToggleListProps = {
  direction: 'vertical' | 'horizontal';
  activeItem: string;
  listItems: string[];
  disabled: boolean;
  onChange?(value: string): void;
};

const itemIncrement = {
  x: 100,
  y: 44,
};

const getXY = (
  indicatorNode: any,
  direction: string
): { x: number; y: number } => {
  let x = -10;
  let y = 0;

  if (indicatorNode) {
    if (direction === 'horizontal') {
      x = indicatorNode.offsetLeft - 48;
    } else {
      y = indicatorNode.offsetTop - 20;
    }
  }

  return { x, y };
};

// TODO: need to work out what is going on with horizontal toggle on mount and datasetchange
const TemporalToggleList: React.FC<TemporalToggleListProps> = (props) => {
  const { direction, activeItem, listItems, disabled, onChange } = props;
  const activeIndicatorRef = React.useRef(null);
  const initialPositionSet = React.useRef(false);
  const prevActiveIndex = React.useRef(0);
  const activeItemIndex = React.useMemo(() => listItems.indexOf(activeItem), [
    activeItem,
    listItems,
  ]);

  const [{ x, y }, set] = useSpring(() => ({
    x: 0,
    y: 0,
    config: { precision: 1 },
  }));

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const newIndex = +(e.currentTarget.dataset.index as string);
    if (newIndex !== activeItemIndex) {
      prevActiveIndex.current = activeItemIndex;
      onChange?.(e.currentTarget.dataset.value as string);
    }
  };

  const moveItemX = React.useCallback(
    (indexDiff: number) => {
      const newX = indexDiff * itemIncrement.x;
      // @ts-ignore
      set({ x: x.getValue() + newX });
    },
    [set, x]
  );

  const moveItemY = React.useCallback(
    (indexDiff: number) => {
      const newY = indexDiff * itemIncrement.y;
      // @ts-ignore
      set({ y: y.getValue() + newY });
    },
    [set, y]
  );

  const handleResize = React.useCallback(() => {
    const newPos = getXY(activeIndicatorRef.current, direction);
    const indexDiff = 0 - activeItemIndex;

    if (direction === 'horizontal') {
      set({ x: newPos.x + indexDiff * itemIncrement.x });
    } else {
      set({ y: newPos.y + indexDiff * itemIncrement.y });
    }
  }, [activeItemIndex, direction, set]);

  // TODO: look at converting to MuationObserver
  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  React.useLayoutEffect(() => {
    if (activeIndicatorRef.current !== null) {
      if (initialPositionSet.current === true) {
        if (direction === 'horizontal') {
          moveItemX(prevActiveIndex.current - activeItemIndex);
        } else {
          moveItemY(prevActiveIndex.current - activeItemIndex);
        }
      } else {
        handleResize();
        initialPositionSet.current = true;
      }
    }
    // having moveItemX/Y as dependencies makes effect trigger from external changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeItemIndex, direction, set, handleResize]);

  return (
    <div
      className={clsx(
        styles.temporalToggleRoot,
        styles[`${direction}`],
        disabled && styles.disabled
      )}
    >
      <div
        ref={activeIndicatorRef}
        className={clsx(styles.activeIndicator, styles[direction])}
      />

      <animated.div
        style={{
          transform: interpolate(
            [x, y] as any,
            (x, y) => `translate3d(${x}px, ${y}px, 0)`
          ),
        }}
        className={styles[`${direction}List`]}
      >
        {listItems.map((item, index) => (
          <TemporalFilter
            key={item}
            onClick={handleClick}
            data-value={item}
            data-index={index}
            active={activeItemIndex === index}
          >
            {item}
          </TemporalFilter>
        ))}
      </animated.div>
    </div>
  );
};

export default TemporalToggleList;
