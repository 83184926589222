import min from 'lodash/min';
import max from 'lodash/max';

export default function getMinMaxAreaData(accumulated: any) {
  return Object.keys(accumulated).map((year) => [
    +year,
    parseInt(`${min(accumulated[year])}`),
    parseInt(`${max(accumulated[year])}`),
  ]);
}
