import * as React from 'react';
import SearchContextProvider, { SearchData } from './SearchContext';

export type SearchProps = {
  data: SearchData[];
  value?: string;
  onSelect(id: string, type: 'lan' | 'kommun', value: string): void;
};

const Search: React.FC<SearchProps> = (props) => {
  const { value, data, onSelect, children } = props;
  return (
    <SearchContextProvider value={value} data={data} onSelect={onSelect}>
      {children}
    </SearchContextProvider>
  );
};

export default Search;
