import * as React from 'react';
import { animated } from 'react-spring';
import Bar, { BarProps } from 'components/Bar';
import styles from './Tooltip.module.scss';

type TooltipProps = Pick<BarProps, 'value'> & {
  x: string | number;
  y: string | number;
  name: string;
};

const Tooltip: React.FC<TooltipProps> = (props) => {
  const { x, y, name, value } = props;
  return (
    <animated.div
      className={styles.tooltip}
      style={{
        top: y,
        left: x,
      }}
    >
      <Bar value={value} width={30} condensed />
      <p>{name}</p>
    </animated.div>
  );
};

export default Tooltip;
